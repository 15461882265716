<template>
    <collapsible :id="`section-${id}`" class="" :isOpen="open" @state="updateState" :onCollapseEnd="collapseEnd" :change="change">
        <template v-slot:trigger>

            <div class="container-fluid">
                <div :id="`section-header-${id}`" class="row align-items-center section-header pm-section-header pointer py-2" :class="`bg-${colours[0]}`">
                    <div class="col-auto px-4" >
                        <div class="arrow" :class="{[`col-${colours[1]}`]: true, 'arrow-open': open}"  v-html="C.ARROW"></div>
                    </div>
                    <div class="col-auto pl-0" >
                        <span class="upper" :class="`col-${colours[1]}`">{{ copy.title }}</span>
                    </div>
                </div>
            </div>
        </template>

        <!-- PRIMARY MODULES -->
<div class="container-fluid">
<div class="row" >
<div class="col">
        <div class="container-fluid px-5">
            <div class="row px-5">
                <div class="col">
                    <div class="my-3" v-html="copy.copy" />
                    <div class="mb-3" v-html="copy.help" />
                </div>
            </div>
        </div>
</div>
</div>
</div>

        <div class="container-fluid px-0 mb-3">
            <!-- <div class="row no-gutters my-3">
                <div class="col">
                </div>
            </div> -->
            <div class="row no-gutters my-3">
                <div class="col-auto px-1 primary-module-margin"></div>
                <div v-for="(mod, index) in config.modules" :key="mod.item_id" class="col text-center px-2" v-tooltip="getTip(mod.text, index)">
                    <div
                        class="primary-module upper heavy"
                        :class="[`colour-${index}`, { 'primary-module-fade': selectedPrimaryModules.length > 0 && !selectedPrimaryModules.includes(mod.item_id) }]"
                    >
                        <h6>{{ mod.description }}</h6>
                    </div>
                </div>
                <div class="col-auto px-1 primary-module-margin"></div>
            </div>
            <div class="row no-gutters">
                <div class="col-auto px-1 bg-0 primary-module-margin primary-module" :class="{ 'primary-module-fade': selectedPrimaryModules.length > 0 && !selectedPrimaryModules.includes(config.modules[0].item_id) }"></div>
                <div v-for="(mod, index) in config.modules" :key="mod.item_id" class="col text-center" v-tooltip="getTip(mod.text, index)">
                    <div class="primary-module primary-module-pic-row text-center" :class="[`bg-${index}`, { 'primary-module-fade': selectedPrimaryModules.length > 0 && !selectedPrimaryModules.includes(mod.item_id) } ]">
                        <img class="primary-module-pic" :src="require(`../assets/primary-module-${index}-thumb.png`)" :alt="mod.title" />
                    </div>
                </div>
                <div
                    class="col-auto px-1 primary-module-margin primary-module"
                    :class="[`bg-${config.modules.length - 1}`, { 'primary-module-fade': selectedPrimaryModules.length > 0 && !selectedPrimaryModules.includes(config.modules[config.modules.length - 1].item_id) }]"
                ></div>
            </div>
            <div class="row no-gutters fade-row">
            </div>
            <!-- main area -->

            <collapsible-v2 id="section-levers" class="row no-gutters mt-3 align-items-end" :isOpen="true" className="levers" @state="onSubmoduleChange">
                <template v-slot:trigger>
                    <div v-tooltip="{ content: C.FOLD_TIP, offset: 5, placement: 'right', trigger: 'manual', show: showTip, autoHide: true, hideOnTargetClick: true }">
                        <div class="levers-word">{{ C.LEVERS }}</div>
                        <div class="levers-arrow" v-html="C.ARROW" />
                    </div>
                </template>
                <!-- levers rows -->
                <div class="row no-gutters align-items-end">
                    <div v-for="num in config.modules.length" :key="num" class="col text-center px-2">
                        <div
                            v-for="submodule in config.modules[num-1].sub_modules.levers"
                            :key="submodule.item_id"
                            class="mt-1 submodule-box lever text-center upper d-flex align-items-center justify-content-center sub-module hyphenate"
                            :class="[
                                `colour-${num-1}`,
                                `border-${num-1}`,
                                { 'sub-module-fade': selectedSubModules.length > 0 && !selectedSubModules.includes(submodule.item_id) }
                            ]"
                        >
                            {{ submodule.title }}
                        </div>
                    </div>
                </div>
            </collapsible-v2>

            <div class="row no-gutters mt-2">
                <div class="col-auto my-2 px-1 primary-module-margin separator"></div>
                <div class="col my-2 separator"></div>
                <div class="col-auto my-2 px-1 primary-module-margin separator"></div>
            </div>

            <collapsible-v2 id="section-influences" class="row no-gutters align-items-start" :isOpen="true" className="influences" @state="onSubmoduleChange">
                <template v-slot:trigger>
                    <!-- <div v-tooltip="{ content: C.FOLD_TIP, offset: 5, placement: 'right'}"> -->
                        <div class="influences-arrow mt-2" v-html="C.ARROW" />
                        <div class="influences-word">{{ C.INFLUENCES }}</div>
                    <!-- </div> -->
                </template>
                <div class="row no-gutters align-items-start">
                    <div v-for="num in config.modules.length" :key="num" class="col text-center px-2">
                        <div
                            v-for="submodule in config.modules[num-1].sub_modules.influences"
                            :key="submodule.item_id"
                            class="mt-1 submodule-box influences text-center upper d-flex align-items-center justify-content-center sub-module hyphenate"
                            :class="[
                                `colour-${num-1}`,
                                `border-${num-1}`,
                                { 'sub-module-fade': selectedSubModules.length > 0 && !selectedSubModules.includes(submodule.item_id) }
                            ]"
                        >
                            {{ submodule.title }}
                        </div>
                    </div>
                </div>
            </collapsible-v2>
        </div>

        <div class="container-fluid px-0">
            <div class="row no-gutters mb-3">
                <div class="col-auto px-1 primary-module-margin"></div>
                <div v-for="(mod, index) in config.modules" :key="mod.item_id" class="col text-center px-2">
                    <div
                        class="primary-module upper"
                        :class="[`colour-${index}`, { 'primary-module-fade': selectedPrimaryModules.length > 0 && !selectedPrimaryModules.includes(mod.item_id) }]"
                    >
                        <h6>{{ mod.description }}</h6>
                    </div>
                </div>
                <div class="col-auto px-1 primary-module-margin"></div>
            </div>
            <div class="row no-gutters py-0">
                <div class="col-auto px-1 bg-0 primary-module-margin primary-module" :class="{ 'primary-module-fade': selectedPrimaryModules.length > 0 && !selectedPrimaryModules.includes(config.modules[0].item_id) }"></div>
                <div v-for="(mod, index) in config.modules" :key="mod.item_id" class="col py-0 text-center">
                    <div class="primary-module text-center lower-rainbow" :class="[`bg-${index}`, { 'primary-module-fade': selectedPrimaryModules.length > 0 && !selectedPrimaryModules.includes(mod.item_id) } ]">
                        &nbsp;
                    </div>
                </div>
                <div
                    class="col-auto px-1 primary-module-margin primary-module"
                    :class="[`bg-${config.modules.length - 1}`, { 'primary-module-fade': selectedPrimaryModules.length > 0 && !selectedPrimaryModules.includes(config.modules[config.modules.length - 1].item_id) }]">
                </div>
            </div>
            <div class="row no-gutters lower-rainbow fade-row-inv">
            </div>
        </div>

    </collapsible>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-caller */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import 'vue-collapsible-component/lib/vue-collapsible.css';
// import Collapsible from 'vue-collapsible-component';
import Collapsible from './Collapsible.vue';
import CollapsibleV2 from './CollapsibleV2.vue';

import { CONST as C } from '../lang';

export default {
    name: 'CollapsibleSectionV2',
    props: {
        copy: Object,
        config: Object,
        colours: Array,
        id: Number,
        collapseEnd: Function,
        selectedSubModules: Array,
        selectedPrimaryModules: Array,
    },
    components: {
        Collapsible,
        CollapsibleV2,
    },
    data() {
        return {
            C,
            open: true,
            change: true,
            showTip: true,
        };
    },
    watch: {
        // items: function () {
        //     // console.log('items changed', newVal)
        //     this.change = !this.change;
        // },
    },
    methods: {
        updateState(state) {
            this.open = state;
            this.showTip = false;
        },
        onSubmoduleChange() {
            this.showTip = false;
            this.change = !this.change;
            // this.changeTrigger = !this.changeTrigger;
        },
        getTip(def, index) {
            return {
                content: def,
                offset: 5,
                placement: 'top',
                classes: `mod-tip-${index}`,
            };
        },
    },
};

</script>
