/* eslint-disable import/prefer-default-export */
export const CONST = {
    HEADING:    'CONFIGURATOR',
    START_APP_ID:    23240786,
    LOADING_ERROR:     'Sorry, unable to load data.',
    LOADING_TMEOUT:    25, // seconds

    INTRO_ID: 1,
    PERSPECTIVES_ID: 2,
    QUESTIONS_ID: 3,
    SUCCESS_FACTORS_ID: 4,
    MODULES_ID: 5,
    // INTRO_HEADING: 'Introduction',
    // INTRO_COPY: 'In non velit non ligula laoreet ultrices. Morbi id urna in diam dignissim feugiat. Ut vitae enim eu est vehicula gravida. Quisque aliquet, quam elementum condimentum feugiat, tellus odio consectetuer wisi, vel nonummy sem neque in elit. Nunc auctor bibendum eros.',
    // PERSPECTIVES_HEADING: 'Perspectives',
    // PERSPECTIVES_COPY: 'Sed aliquam, nunc eget euismod ullamcorper, lectus nunc ullamcorper orci, fermentum bibendum enim nibh eget ipsum. Fusce mattis est id diam. Morbi a est quis orci consequat rutrum. Nullam ultrices, diam tempus vulputate egestas, eros pede vio. Nam consectete enim eu est vehicula gravida. ',
    // QUESTIONS_HEADING: 'Questions',
    // QUESTIONS_COPY: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Pellentesque vel dui sed orci faucibus iaculis. Fusce ultrices, neque id dignisu, eros.',
    // CTS_HEADING: 'Critical to success',
    // CTS_COPY: 'Suspendisse id velit vitae ligula volutpat condimentum. Aliquam iaculis.',
    PM_HEADING: 'Primary Modules',
    FOLD_TIP: 'Click this arrow or its opposite below to fold or restore each half of the submodules.',
    LEVERS: 'LEVERS',
    INFLUENCES: 'INFLUENCES',
    ARROW: '<svg aria-labelledby="title" xmlns="http://www.w3.org/2000/svg" width="44.991" height="49.056" viewBox="0 0 44.991 49.056"><title id="title" lang="en">Arrow</title><path d="M20.464 0l-5.303 5.303 15.474 15.475H0v7.5h30.635L15.161 43.753l5.303 5.303 24.527-24.528z"/></svg>',
};
