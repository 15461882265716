// import { createApp } from 'vue'
// import App from './App.vue'

// createApp(App).mount('#app')

import Vue from 'vue';
// https://github.com/Akryum/v-tooltip#usage
import VTooltip from 'v-tooltip';
import App from './App.vue';

Vue.config.productionTip = false;
Vue.use(VTooltip);

new Vue({
    render: (h) => h(App),
}).$mount('#app');
