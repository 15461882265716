exports.fetchConfig = async (id) => {
    const startTime = new Date().getTime();
    const response = await fetch(`${process.env.VUE_APP_API_PATH}${process.env.VUE_APP_DATA_PATH}/${id}`, {
        method: 'GET',
    });

    // console.log(response);
    if (response.status !== 200) {
        return Promise.reject(response.statusText);
    }
    const data = await response.json();
    // console.log(offset, data);

    return new Promise((resolve, reject) => {
        if (data.error) reject(data.message);
        const offset = new Date().getTime() - startTime;
        if (offset > 1000) resolve(data);
        setTimeout(() => resolve(data), 1010 - offset);
    });
};
