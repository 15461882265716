<template>
  <div id="app">
    <div id="page-header" class="container-fluid header p-2 px-3">
        <div class="row align-items-center">
            <img class="col-auto p-0 ml-2 mr-3 gp-logo" alt="Group Partners Logo" src="./assets/GP_Box_Logo.svg" />
            <h3 class="col-auto px-0 my-0"><span class="normal">{{ C.HEADING }}</span></h3>
            <!-- <h3 class="header-title ml-auto my-0 pr-3">{{ copy ? copy.title.toUpperCase(): '' }}</h3> -->
        </div>
    </div>
    <div v-if="loading" class="container-fluid mb-5 pt-5">
        <div class="row align-items-center justify-content-center mb-5 pt-5">
            <rotate-loader
                v-if="!error"
                :loading="loading"
                color="#01579b"
                size="18px"
            />
            <div v-else>{{ C.LOADING_ERROR }}</div>
        </div>
    </div>
    <template v-else>

        <collapsible-section
            :colours="['white','blue']"
            :copy="introCopy"
            :id="1"
        />
        <collapsible-section
            :id="2"
            :colours="['blue','white']"
            :copy="perspectivesCopy"
            :items="config.perspectives.filter(f => f.questions)"
            :selectedItems="selectedPerspectivesTitles"
            :hover="true"
            type="Perspectives"
            @update="update"
        />
        <collapsible-section
            :id="3"
            :colours="['white','blue']"
            :copy="questionsCopy"
            :items="relevantQuestions"
            :selectedItems="selectedQuestionsTitles"
            type="Questions"
            @update="update"
        />

        <collapsible-section-v2
            :id="9"
            :colours="['white','blue']"
            :copy="modulesCopy"
            :config="config"
            :selectedSubModules="selectedSubModules"
            :selectedPrimaryModules="selectedPrimaryModules"
        />

        <collapsible-section
            :id="4"
            :colours="['blue','white']"
            :copy="successFactorsCopy"
            :items="config.critical_to_success"
            :selectedItems="selectedCriticalToSuccessTitles"
            :collapseEnd="onCTSCollapseEnd"
            type="CriticalToSuccess"
            @update="update"
        />
        <div id="bottom"></div>
    </template>
  </div>
</template>

<script>
/* eslint-disable camelcase */

// import Vue from 'vue';
import { fetchConfig } from '@/api/config';
import RotateLoader from 'vue-spinner/src/RotateLoader.vue'; // http://greyby.github.io/vue-spinner/
import CollapsibleSection from './components/CollapsibleSection.vue';
import CollapsibleSectionV2 from './components/CollapsibleSectionV2.vue';
// import Collapsible from './components/Collapsible.vue';
// import CollapsibleV2 from './components/CollapsibleV2.vue';
import { CONST as C } from './lang';

// function getPosition(el) {
//   let xPos = 0;
//   let yPos = 0;
//   while (el) {
//     if (el.tagName === 'BODY') {
//       // deal with browser quirks with body/window/document and page scroll
//         const xScroll = el.scrollLeft || document.documentElement.scrollLeft;
//         const yScroll = el.scrollTop || document.documentElement.scrollTop;
//       xPos += (el.offsetLeft - xScroll + el.clientLeft);
//       yPos += (el.offsetTop - yScroll + el.clientTop);
//     } else {
//       // for all other non-BODY elements
//       xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
//       yPos += (el.offsetTop - el.scrollTop + el.clientTop);
//     }
//     // eslint-disable-next-line no-param-reassign
//     el = el.offsetParent;
//   }
//   return {
//     x: xPos,
//     y: yPos
//   };
// }

export default {
    name: 'App',
    components: {
        RotateLoader,
        // Collapsible,
        // CollapsibleV2,
        CollapsibleSection,
        CollapsibleSectionV2,
    },
    data() {
        return {
            C,
            loading: true,
            error: null,
            config: {},
            selectedPerspectives: [],
            selectedQuestions: [],
            selectedCriticalToSuccess: [],
            // showTip: true,
            // changeTrigger: false,
            // pmIsOpen: true,
            // elements: null,
        };
    },
    async mounted() {
        this.getConfig();
    },
    computed: {
        introCopy() {
            return this.config.copy.find((e) => e.section_id === C.INTRO_ID);
        },
        perspectivesCopy() {
            return this.config.copy.find((e) => e.section_id === C.PERSPECTIVES_ID);
        },
        questionsCopy() {
            return this.config.copy.find((e) => e.section_id === C.QUESTIONS_ID);
        },
        successFactorsCopy() {
            return this.config.copy.find((e) => e.section_id === C.SUCCESS_FACTORS_ID);
        },
        modulesCopy() {
            return this.config.copy.find((e) => e.section_id === C.MODULES_ID);
        },
        allQuestions() {
            return this.config.perspectives.reduce((arr, p) => {
                if (p.questions) return [...arr, ...p.questions];
                return arr;
            }, []);
        },
        relevantQuestions() {
            if (this.selectedPerspectives.length === 0) return [];
            return this.selectedPerspectives.reduce((arr, p) => {
                const persp = this.config.perspectives.find((x) => x.item_id === p);
                if (persp && persp.questions) arr.push({ title: persp.title, questions: persp.questions });
                return arr;
            }, []);
        },
        selectedPerspectivesTitles() {
            if (this.selectedPerspectives.length === 0) return [];
            return this.selectedPerspectives.map((m) => this.config.perspectives.find((z) => z.item_id === m));
        },
        selectedQuestionsTitles() {
            if (this.selectedQuestions.length === 0) return [];
            return this.selectedQuestions.map((m) => this.allQuestions.find((z) => z.item_id === m));
        },
        selectedCriticalToSuccessTitles() {
            if (this.selectedCriticalToSuccess.length === 0) return [];
            return this.selectedCriticalToSuccess.map((m) => this.config.critical_to_success.find((z) => z.item_id === m));
        },
        selectedPrimaryModules() {
            if (this.selectedQuestions.length === 0) return [];
            return Array.from(this.selectedQuestions.reduce((set, q) => new Set([...Array.from(set), ...this.allQuestions.find((f) => f.item_id === q).primary_modules]), new Set()));
        },
        selectedSubModules() {
            if (this.selectedCriticalToSuccess.length === 0) return [];
            return Array.from(this.selectedCriticalToSuccess.reduce((set, q) => {
                const item = this.config.critical_to_success.find((r) => r.item_id === q);
                return new Set([
                    ...Array.from(set),
                    ...item.levers,
                    ...item.influences,
                ]);
            }, new Set()));
        },
    },
    methods: {
        async getConfig() {
            try {
                this.config = await fetchConfig(C.START_APP_ID);
                this.loading = false;
            } catch (e) {
                console.error(e);
                this.error = C.LOADING_ERROR;
            }
        },
        onSubmoduleChange() {
            // this.showTip = false;
            // this.changeTrigger = !this.changeTrigger;
        },
        onCTSCollapseEnd(open) {
            // console.log('onCTSCollapseEnd', document.body.scrollHeight);
            if (open) {
                document.getElementById('bottom').scrollIntoView(false);
            }
        },
        // getElements() {
        //     this.elements = [
        //         document.getElementById('page-header'),
        //         document.getElementById('section-header-1'),
        //         document.getElementById('section-header-2'),
        //         document.getElementById('section-header-3'),
        //         document.getElementById('section-header-4'),
        //         document.getElementById('lower'),
        //     ];
        // },
        update({ type, item_id, state }) {
            this.onSubmoduleChange();
            // console.log(type, item_id, state);
            const ind = this[`selected${type}`].findIndex((i) => i === item_id);
            if (ind === -1) this[`selected${type}`].push(item_id);
            else this[`selected${type}`].splice(ind, 1);

            if (type === 'Perspectives' && state === false) {
                // remove question selections for this perspective too
                const persp = this.config.perspectives.find((x) => x.item_id === item_id);
                if (persp.questions) {
                    persp.questions.forEach((q) => {
                        const w = this.selectedQuestions.findIndex((i) => i === q.item_id);
                        if (w > -1) this.selectedQuestions.splice(w, 1);
                    });
                }
            }

            // move the bottom section down depending on the top height
            // Vue.nextTick().then(() => {
            //     if (!this.elements) this.getElements();
            //     const num = this.elements.slice(0, this.elements.length - 1).reduce((tot, el) => {
            //         // eslint-disable-next-line no-param-reassign
            //         tot += el.scrollHeight;
            //         return tot;
            //     }, 0);
            //     // console.log(num);
            //     this.elements[this.elements.length - 1].style.top = `${num}px`;
            // });
        },
    },
};

</script>

<style lang="scss">
    @import "@/scss/tooltip";
    @import '@/scss/configurator.scss';
</style>
