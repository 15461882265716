<template>
  <div class="upper pointer" :class="[`border-${colours[1]}`,`col-${textColour}`, { [`bg-${colours[1]}`]: isSelected }, isSmall ? 'pill-small' : 'pill']" @click="onClick">
      <div class="pill-text d-flex align-items-center">{{ text }}<div v-if="isSmall" class="ml-2 pill-closer d-flex align-items-center justify-content-center" :class="[`col-${colours[1]}`,`bg-${colours[0]}`]" @click.stop="onClose">&times;</div></div>
  </div>
</template>

<script>
export default {
    name: 'Pill',
    props: {
        isSelected: Boolean,
        item_id: Number,
        text: String,
        colours: Array,
        small: Boolean,
    },
    data() {
        return {
            // selected: this.small,
            isSmall: this.small,
        };
    },
    computed: {
        textColour() {
            return this.isSelected ? this.colours[0] : this.colours[1];
            // return this.selected ? this.colours[0] : this.colours[1];
        },
        // bgColour() {
        //     return this.selected ? this.colours[1] : f;
        // },
    },
    methods: {
        onClose() {
            this.$emit('on-click', { state: false, item_id: this.item_id });
        },
        onClick() {
            if (!this.isSmall) {
                // this.selected = !this.selected;
                // this.$emit('on-click', { state: this.selected, item_id: this.item_id });

                this.$emit('on-click', { state: !this.isSelected, item_id: this.item_id });
            }
        },
    },
};
</script>
