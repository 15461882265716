var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('button',{staticClass:"col-auto px-1 pl-2 primary-module-margin text-center",class:( _obj = {
            Collapsible__trigger: true
        }, _obj[("Collapsible__triggerV2–" + _vm.className + "--open")] = !_vm.transitioning && _vm.open, _obj[("Collapsible__triggerV2–" + _vm.className + "--closed")] = !_vm.transitioning && !_vm.open, _obj[("Collapsible__triggerV2–" + _vm.className + "--transitioning")] = _vm.transitioning, _obj[("Collapsible__triggerV2–" + _vm.className + "--transitioning-in")] = _vm.transitioning && !_vm.open, _obj ),on:{"click":_vm.handleClick}},[_vm._t("trigger")],2),_c('div',{staticClass:"col",class:{
            Collapsible__content: true,
            'Collapsible__contentV2--open': !_vm.transitioning && _vm.open,
            'Collapsible__contentV2--closed': !_vm.transitioning && !_vm.open,
            'Collapsible__contentV2--transitioning': _vm.transitioning,
        },style:({
            height: _vm.height,
            transitionProperty: 'height',
            transitionDuration: _vm.transitionDuration,
            transitionTimingFunction: _vm.transitionTimingFunction,
            transitionDelay: _vm.transitionDelay,
        }),on:{"transitionend":_vm.handleEnd}},[_c('div',{ref:"inner",staticClass:"Collapsible__contentInner"},[_vm._t("default")],2)]),_c('div',{staticClass:"col-auto px-1 primary-module-margin"})])}
var staticRenderFns = []

export { render, staticRenderFns }