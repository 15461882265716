<template>
    <div class="">
        <button
            class="col-auto px-1 pl-2 primary-module-margin text-center"
            @click="handleClick"
            :class="{
                Collapsible__trigger: true,
                [`Collapsible__triggerV2–${className}--open`]: !transitioning && open,
                [`Collapsible__triggerV2–${className}--closed`]: !transitioning && !open,
                [`Collapsible__triggerV2–${className}--transitioning`]: transitioning,
                [`Collapsible__triggerV2–${className}--transitioning-in`]: transitioning && !open,
            }"
        >
            <slot name="trigger" ></slot>
        </button>

        <div
            class="col"
            :class="{
                Collapsible__content: true,
                'Collapsible__contentV2--open': !transitioning && open,
                'Collapsible__contentV2--closed': !transitioning && !open,
                'Collapsible__contentV2--transitioning': transitioning,
            }"
            :style="{
                height,
                transitionProperty: 'height',
                transitionDuration,
                transitionTimingFunction,
                transitionDelay,
            }"
            @transitionend="handleEnd"
        >
            <div class="Collapsible__contentInner" ref="inner">
                <slot></slot>
            </div>
        </div>
        <div class="col-auto px-1 primary-module-margin"></div>
    </div>
</template>

<script>
export default {
    name: 'CollapsibleV2',
    data() {
        return {
            open: this.isOpen,
            height: this.isOpen ? 'auto' : '0px',
            closeOnNextTick: false,
            transitioning: false,
        };
    },
    props: {
        // openLabel: {
        //     type: String,
        //     default: 'Close me',
        // },
        // closedLabel: {
        //     type: String,
        //     default: 'Open me',
        // },
        className: {
            type: String,
            default: '',
        },
        transitionDuration: {
            type: String,
            default: '400ms',
        },
        transitionTimingFunction: {
            type: String,
            default: 'ease',
        },
        transitionDelay: {
            type: String,
            default: '0s',
        },
        isOpen: {
            default: true,
            type: Boolean,
        },
        // change: {
        //     default: true,
        //     type: Boolean,
        // },
        // onCollapse: {
        //     default: () => {},
        //     type: Function,
        // },
        // onCollapseEnd: {
        //     default: () => {},
        //     type: Function,
        // },
    },
    mounted() {
        this.height = `${this.$refs.inner.scrollHeight}px`; // set the transitioning value otherwise it won't transition first time in firefox
        // setTimeout(() => { this.height = this.isOpen ? 'auto' : '0px'; }, 250);
    },
    methods: {
        handleClick() {
            // console.log('click');
            // this.onCollapse(!this.isOpen);
            this.open = !this.open;
            this.$emit('state', this.open);
            setTimeout(() => { this.$emit('state', this.open); }, 200);
        },
        handleEnd() {
            // console.log('handleEnd', this.height);
            // if (this.height !== '0px') {
            //     this.height = 'auto';
            // }
            this.transitioning = false;
            // this.onCollapseEnd(this.open);
            this.$emit('state', this.open);
        },
    },
    watch: {
        open(newVal, oldVal) {
            this.transitioning = true;
            this.height = `${this.$refs.inner.scrollHeight}px`;
            if (oldVal === true) {
                this.closeOnNextTick = true;
            }
            // console.log('this.height', this.height, this.closeOnNextTick );
        },
        isOpen(newVal) {
            this.open = newVal;
        },
        // height(newVal) {
        //     console.log('height changed', newVal);
        // },
        // change() {
        //     this.$nextTick(() => {
        //         // console.log('change changed', this.$refs.inner.scrollHeight);
        //         this.height = `${this.$refs.inner.scrollHeight}px`;
        //     });
        // },
    },
    updated() {
        this.$nextTick(() => {
            window.setTimeout(() => {
                if (this.closeOnNextTick) {
                    this.height = '0px';
                    this.closeOnNextTick = false;
                }
            });
        });
    },
};
</script>

<style scoped>
/* .Collapsible__content {
  overflow: hidden;
}

.Collapsible__contentInner {
  height: auto;
} */

/* .Collapsible__trigger {
   appearance: none;
  border: none;
  border-width: none;
  border-color: none;
  border-style: none;
  background: transparent;
  border-radius: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  text-align: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  cursor: pointer;
} */
</style>
