<template>
    <collapsible :id="`section-${id}`" class="" :isOpen="open" @state="updateState" :onCollapseEnd="collapseEnd" :change="change">
        <template v-slot:trigger>
            <!-- <div class="customTrigger">
                <h2>Custom open trigger element</h2>
            </div> -->
            <div class="container-fluid">
                <div :id="`section-header-${id}`" class="row align-items-center section-header pointer py-2" :class="`bg-${colours[0]}`">
                    <div class="col-auto px-4" >
                        <div class="arrow" :class="{[`col-${colours[1]}`]: true, 'arrow-open': open}"  v-html="C.ARROW"></div>
                    </div>
                    <div class="col-auto pl-0" >
                        <!-- <span class="upper" :class="`col-${colours[1]}`">{{ title }} {{ items ? items.length : '' }}</span> -->
                        <span class="upper" :class="`col-${colours[1]}`">{{ copy.title }}</span>
                    </div>
                    <!-- <div class="col pr-4" :class="{ fade: open }"> -->
                    <div class="col pr-4 pill-holder-small pl-1" :class="open ? 'fade' : 'show'">
                        <pill
                            v-for="(item, index) in selectedItems"
                            :key="index"
                            :isSelected="true"
                            :text="item.title || item.question"
                            :item_id="item.item_id"
                            :colours="colours"
                            :small="true"
                            @on-click="relayClick"
                        />
                    </div>
                </div>
            </div>
        </template>

        <!-- <template v-slot:closedTrigger> -->
            <!-- <div class="customTrigger">
                <h2>Custom closed trigger element</h2>
            </div> -->
        <!-- </template> -->

        <!-- <div id="example2">
        <p>
            Lorem ipsum dolor sit ...
        </p>
        </div> -->

        <div class="container-fluid">
            <div class="row" >
                <div :class="`bg-${colours[0]}`" class="col">
                    <div class="container-fluid px-5">
                        <div class="row px-5">
                            <!-- <div class="col-2" :class="`col-${colours[1]}`">{{ copy }}</div> -->
                            <!-- <div class="col"> -->
                            <div class="col">
                                <div class="my-3" :class="`col-${colours[1]}`" v-html="copy.copy" />
                                <div v-if="(copy.help && type === 'Questions' && items.length > 0) || (copy.help && type !== 'Questions')" class="mb-3" :class="`col-${colours[1]}`" v-html="copy.help" />
                                <div v-if="items" class="mb-3 pill-holder">
                                    <template v-if="type !== 'Questions'">
                                        <pill
                                            v-for="item in items"
                                            :isSelected="selectedItems.some((x) => x.item_id === item.item_id)"
                                            :key="item.item_id"
                                            :text="item.title"
                                            :colours="colours"
                                            :item_id="item.item_id"
                                            @on-click="relayClick"
                                            v-tooltip="getTip(item.definition)"
                                        />
                                    </template>
                                    <template v-else>
                                        <div v-for="(set, index) in items" :key="index" class="row py-2">
                                            <div class= "col-2 my-2 ml-2"><strong>{{ set.title }}</strong></div>
                                            <div class="col">
                                                <pill
                                                    v-for="(item, index) in set.questions"
                                                    :isSelected="selectedItems.some((x) => x.item_id === item.item_id)"
                                                    :key="index"
                                                    :text="item.title"
                                                    :colours="colours"
                                                    :item_id="item.item_id"
                                                    @on-click="relayClick"
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </collapsible>
</template>

<script>
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-caller */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import 'vue-collapsible-component/lib/vue-collapsible.css';
// import Collapsible from 'vue-collapsible-component';
import Collapsible from './Collapsible.vue';
import Pill from './Pill.vue';

import { CONST as C } from '../lang';

// function expandSection(element) {
//     // get the height of the element's inner content, regardless of its actual size
//     const sectionHeight = element.scrollHeight;
//     // have the element transition to the height of its inner content
//     element.style.height = `${sectionHeight}px`;
//     const func = () => {
//         // remove this event listener so it only gets triggered once
//         element.removeEventListener('transitionend', func);
//         // remove "height" from the element's inline styles, so it can return to its initial value
//         element.style.height = null;
//     };
//     element.addEventListener('transitionend', func);
//     // mark the section as "currently not collapsed"
//     element.setAttribute('data-collapsed', 'false');
// }

// function collapseSection(element) {
//     const sectionHeight = element.scrollHeight;
//     const elementTransition = element.style.transition;
//     element.style.transition = '';
//     requestAnimationFrame(() => {
//         element.style.height = `${sectionHeight}px`;
//         element.style.transition = elementTransition;
//         // on the next frame (as soon as the previous style change has taken effect),
//         // have the element transition to height: 0
//         requestAnimationFrame(() => {
//             element.style.height = '0px';
//         });
//         element.setAttribute('data-collapsed', 'true');
//     });
// }

export default {
    name: 'CollapsibleSection',
    props: {
        // title: String,
        copy: Object,
        // help: String,
        type: String,
        items: Array,
        selectedItems: Array,
        colours: Array,
        id: Number,
        hover: {
            type: Boolean,
            default: false,
        },
        collapseEnd: Function,
    },
    components: {
        Collapsible,
        Pill,
    },
    data() {
        return {
            C,
            open: true,
            change: true,
        };
    },
    watch: {
        items: function () {
            // console.log('items changed', newVal)
            this.change = !this.change;
        },
    },
    methods: {
        updateState(state) {
            this.open = state;
        },
        relayClick({ state, item_id }) {
            this.$emit('update', { type: this.type, item_id, state });
        },
        getTip(def) {
            if (!this.hover) return null;
            return { content: def, offset: 5, placement: 'top' };
        },
        // headerClick() {
        // this.open = !this.open;
        //     // expandSection(document.getElementById(evt));
        //     const section = document.getElementById(evt);
        //     const isCollapsed = section.getAttribute('data-collapsed') === 'true';
        //     if (isCollapsed) {
        //         expandSection(section);
        //         section.setAttribute('data-collapsed', 'false');
        //     } else {
        //         collapseSection(section);
        //     }
        // },
    },
};

</script>
